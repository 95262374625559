<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ title }}</h5>
        <small class="text-muted">
          {{ subtitle }}
        </small>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="End Point that returns Country Informations"
          label-for="v-first-name"
        >
          <b-form-input
            id="v-country-api-url"
            v-model="customCountryEndpoint"
            placeholder="https://api.example.com/countries"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="METHOD " label-for="v-first-name">
          <v-select
            v-model="requestMethod"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="requestMethods"
          />
        </b-form-group>
      </b-col>
      
          </b-row>
            <b-row>
      

      <b-col md="4">
         <b-form-group label="Test Username" label-for="v-first-name">
              <b-form-input id="v-first-name" v-model="demoUser" placeholder="something@something.com" />
            </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Test Password" label-for="v-first-name">
              <b-form-input id="v-first-name" v-model="demoPass" placeholder="123456" />
            </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Click & Fetch " label-for="v-first-name">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="fecthCountries"
          >
            Fetch
          </b-button>
        </b-form-group>
      </b-col>
          </b-row>


          
      <!--- Headers Area -->
     
        <div> <div>
          <b-col md="12">
            <b-form
              ref="form"
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Item Name -->
                <b-col md="4">
                  <b-form-group label="Header Name" label-for="item-name">
                    <b-form-input
                      :id="item.id"
                      type="text"
                      placeholder="Authorisation"
                    />
                  </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4">
                  <b-form-group label="Value" label-for="val">
                    <b-form-input id="val" type="text" placeholder="Bearer cdfdsfsdf" />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
              
              

                <!-- Remove Button -->
                <b-col  md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
              
              </b-row>
            </b-form>
          </b-col> 
            <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeateAgain"
          >
          
            <span>Add  Header</span>
          </b-button>  </b-col>
        </div> </div>
      
      <!-- HEaders End -->
     <b-row>
      <b-col md="12">
        <small class="text-danger">
          {{ description }}
        </small>
      </b-col>
      <b-col md="12">
        <small class="text-muted">
          {{ sampleresponse }}
        </small>
      </b-col>
    </b-row>
  
  
    <b-row>
      <b-col md="6">
        <b-alert v-if="importedCountries.length > 0" variant="primary" show>
          <div class="alert-body">
            <span>
              if you continue
              <strong> {{ importedCountries.length }}</strong> Countries will be
              imported .</span
            >
          </div>
        </b-alert>
      </b-col>
      <b-col md="3" v-if="importedCountries.length > 0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="importCountries"
        >
          Import Countries
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
 

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BAlert,
  BForm,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CountryPicker from "../component/CountryPicker.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@axios";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    BForm,
    vSelect,
    CountryPicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [heightTransition],
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedLanguage: "nothing_selected",
      selectedContry: "Turkey",
      requestMethod: "POST",
      demoUser:"",
      demoPass:"",
      requestMethods: ["GET", "POST", "PUT"],
      headers: [],
      importedCountries: [],
      customCountryEndpoint: null,
      customCountryBase: "",
      selectedCountries: [],
      items: [
        {
       
          "Content-Type": "application/json",
       
        },
      ],
      nextTodoId: 2,
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "Russia", text: "Russia" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      languageName: [
        { value: "nothing_selected", text: "Nothing Selected" },
        { value: "English", text: "English" },
        { value: "Chinese", text: "Mandarin Chinese" },
        { value: "Hindi", text: "Hindi" },
        { value: "Spanish", text: "Spanish" },
        { value: "Arabic", text: "Arabic" },
        { value: "Malay", text: "Malay" },
        { value: "Russian", text: "Russian" },
      ],
    };
  },
  props: {
    title: "",
    subtitle: "",
    description: "",
    sampleresponse: "",
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        //console.log("validationForm");
        //console.log(this.selectedCountries.length);

        if (this.selectedCountries.length > 0) {
          resolve(true);
        } else {
          reject();
        }
      });
    },
    importCountries() {
      this.$emit("importdataclicked", this.importedCountries);
    },

    fecthCountries() {

        
      return new Promise((resolve, reject) => {
        var uri = this.customCountryEndpoint;

        var options = {
          method: this.requestMethod,
          body : JSON.stringify({
  "username": this.demoUser,
  "password": this.demoPass
  }),
          headers:this.items,
        };

        const response = fetch(uri, options)
          .then((response) => {
            var data=response.json();

            //console.log(data );
            return data;
          })
          .then((data) => {
            //console.log(data);
            this.importedCountries = data;
            this.$emit("datafetched", data);
            resolve(data);
          })
          .catch((error) => {
            //console.log(error);
            reject(error);
          });
      });
    },

    countrySelected(value) {
      this.selectedCountries = value;
    },

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
};
</script>
