import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClientSettings(ctx, queryParams) {
      //console.log("ClientSettings Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/f/settings', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClientSetting(ctx, { id }) {
      //console.log("single ClientSetting  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/ClientSetting/getClientSettingdetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateClientSettingInfo(ctx, { id }) {
      //console.log("update ClientSetting  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/ClientSetting/getClientSettingdetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

   
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ClientSetting/ClientSettings/ClientSettings', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
