<template>
  <div>
    <form-wizard
      color="#4a8cff"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
        ref="wizard"
      back-button-text="Previous"
      class="wizard-vertical primary mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content title="Login Settings"  :before-change="canSwitch">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Custom Login End Point</h5>
              <small class="text-muted">
                Please add your login url and other additional  details. So users can sign in . <br/>
                To use custom login feature , your endpoint  should prepare some dispatch endpoints to query between our system.
              </small>
            </b-col>
            <b-col md="12">
              
            
               

               <login-end-configuration
                 title="Authentication Url"
   decription=""
    subtitle="Add your end point to receive country informations and map." 
    description="The endpoint should support SSL  and response should return an object  including 'token','fullname','userid','photo','email','username' "
    sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
    @datafetched="tokenReceived"
    :initial-login-info="clientData"
    @importdataclicked="importCountries"
               
               >
               </login-end-configuration>
  
            
            </b-col>
  
          
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- account datails tab -->
      <tab-content title="Branches"  :before-change="canSwitch" >
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Company Branches</h5>
              <small class="text-muted">
                Define branch retrieve method informations to fetch Branch Informations.
              </small>

   </b-col>
            <b-col cols="12" >
               <branch-configuration
                 title=""
   decription=""
    subtitle="Add your end point to receive country informations and map." 
    description="The endpoint should support SSL  and response should return an object  including 'token','fullname','userid','photo','email','username' "
    sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
    @datafetched="tokenReceived"
    :initial-login-info="clientData"
    @importdataclicked="importCountries"
               
               > </branch-configuration>
              
            </b-col>
          
  
          
          </b-row>
        </validation-observer>
      </tab-content>
 
       <!-- account datails tab -->
      <tab-content title="Departments" :before-change="canSwitch" >
           <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Company Branches</h5>
              <small class="text-muted">
                Define branch retrieve method informations to fetch Branch Informations.
              </small>

   </b-col>
            <b-col md="12">
               <department-configuration
                 title=""
   decription=""
    subtitle="Add your end point to receive country informations and map." 
    description="The endpoint should support SSL  and response should return an object  including 'token','fullname','userid','photo','email','username' "
    sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
    @datafetched="tokenReceived"
    :initial-login-info="clientData"
    @importdataclicked="importCountries"
               
               > </department-configuration>
              
            </b-col>
          
  
          
          </b-row>
        </validation-observer>
      </tab-content>
 
        <!-- account datails tab -->
      <tab-content title="Positions"  :before-change="canSwitch">
          <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Company Branches</h5>
              <small class="text-muted">
                Define branch retrieve method informations to fetch Branch Informations.
              </small>

   </b-col>
            <b-col md="12">
               <position-configuration
                 title=""
   decription=""
    subtitle="Add your end point to receive country informations and map." 
    description="The endpoint should support SSL  and response should return an object  including 'token','fullname','userid','photo','email','username' "
    sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
    @datafetched="tokenReceived"
    :initial-login-info="clientData"
    @importdataclicked="importCountries"
               
               > </position-configuration>
              
            </b-col>
          
  
          
          </b-row>
        </validation-observer>
      </tab-content>
 
        <!-- account datails tab -->
      <tab-content title="Employees" :before-change="canSwitch" >
           <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Company Branches</h5>
              <small class="text-muted">
                Define branch retrieve method informations to fetch Branch Informations.
              </small>

   </b-col>
            <b-col md="12">
               <employees-configuration
                 title=""
   decription=""
    subtitle="Add your end point to receive country informations and map." 
    description="The endpoint should support SSL  and response should return an object  including 'token','fullname','userid','photo','email','username' "
    sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
    @datafetched="tokenReceived"
    :initial-login-info="clientData"
    @importdataclicked="importCountries"
               
               > </employees-configuration>
              
            </b-col>
          
  
          
          </b-row>
        </validation-observer>
      </tab-content>
 
        <!-- account datails tab -->
      <tab-content title="Employee Detail" :before-change="canSwitch" >
          <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Company Branches</h5>
              <small class="text-muted">
                Define branch retrieve method informations to fetch Branch Informations.
              </small>

   </b-col>
            <b-col md="12">
               <employee-detail-configuration
                 title=""
   decription=""
    subtitle="Add your end point to receive country informations and map." 
    description="The endpoint should support SSL  and response should return an object  including 'token','fullname','userid','photo','email','username' "
    sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
    @datafetched="tokenReceived"
    :initial-login-info="clientData"
    @importdataclicked="importCountries"
               
               > </employee-detail-configuration>
              
            </b-col>
          
  
          
          </b-row>
        </validation-observer>
      </tab-content>
   <tab-content title="New Employee" :before-change="canSwitch" >
          <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Add new employee</h5>
              <small class="text-muted">
               
              </small>

   </b-col>
            <b-col md="12">
               <employee-add-configuration
                 title=""
                decription=""
                  subtitle="POST API to add new employee" 
                  description=" "
                  sampleresponse=" { 'name':'Amazing Employee', photo:'https://mydomain.com/myphoto.jpg', 'email':'test@myemail.com','username':'testu',  'userid':225,  .... } "
                  @datafetched="tokenReceived"
                  :initial-login-info="clientData"
                  @importdataclicked="importCountries"
               
               >
              </employee-add-configuration>
              
            </b-col>
          
  
          
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content title="Migrate">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Social Links</h5>
            <small class="text-muted">Enter Your Social Links</small>
          </b-col>
          <b-col md="6">
            <b-form-group label="Twitter" label-for="v-twitter">
              <b-form-input
                id="v-twitter"
                placeholder="https://twitter.com/abc"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Facebook" label-for="v-facebook">
              <b-form-input
                id="v-facebook"
                placeholder="https://facebook.com/abc"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Google+" label-for="v-google-plus">
              <b-form-input
                id="v-google-plus"
                placeholder="https://plus.google.com/abc"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="LinkedIn" label-for="v-linked-in">
              <b-form-input
                id="v-linked-in"
                placeholder="https://linkedin.com/abc"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BRow, BCol, BButton, BFormGroup, BFormInput,BAlert } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"; 
import Ripple from "vue-ripple-directive";
import clientSettingsStoreModule from './migrationStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CustomApiBuilder from './CustomApiBuilder.vue'
import BranchConfiguration from './BranchConfiguration.vue'
import DepartmentConfiguration from './DepartmentConfiguration.vue'
import PositionConfiguration from './PositionConfiguration.vue'
import EmployeesConfiguration from './EmployeeConfiguration.vue'
import EmployeeDetailConfiguration from './EmployeeDetailConfiguration.vue'

import EmployeeAddConfiguration from './EmployeeNewConfiguration.vue'
import LoginEndConfiguration from './LoginEndConfiguration.vue' 
import axios from "@axios";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    vSelect, 
    CustomApiBuilder,
    LoginEndConfiguration,
    BranchConfiguration,
    DepartmentConfiguration,
    PositionConfiguration,
    EmployeesConfiguration,
    EmployeeDetailConfiguration,
    EmployeeAddConfiguration,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedLanguage: "nothing_selected",
      selectedContry: "Turkey",
      importedCountries: [], 
      baseClientMigrateSettings:{
        loginInfo:{
          url:"",
          fetched:false,
          loginusername:"",
          loginpassword:"",
          requestMethod:"POST",
          loginToken:"",
          useTokeninHeader:true,
        },
        branchInfo:{
          url:"",
          fetched:false,
          loginusername:"",
          loginpassword:"",
          loginToken:"",
        },
        departmentInfo:{
          url:"",
          fetched:false,
          loginusername:"",
          loginpassword:"",
          loginToken:"",
        },
        positionInfo:{
          url:"",
          fetched:false,
          loginusername:"",
          loginpassword:"",
          loginToken:"",
        },

      },
     
      demoToken:null,
      customCountryEndpoint: null,
      customCountryBase: "",
      selectedCountries: [],
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "Russia", text: "Russia" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      languageName: [
        { value: "nothing_selected", text: "Nothing Selected" },
        { value: "English", text: "English" },
        { value: "Chinese", text: "Mandarin Chinese" },
        { value: "Hindi", text: "Hindi" },
        { value: "Spanish", text: "Spanish" },
        { value: "Arabic", text: "Arabic" },
        { value: "Malay", text: "Malay" },
        { value: "Russian", text: "Russian" },
      ],
    };
  },
   setup() {
    const clientData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-migration-settings'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-migration-settings/fetchMigrationSettings', { id: router.currentRoute.params.id })
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        clientData.value =  response.data;
        //console.log("courses assigned to course data");
          //console.log(clientData.value);
                  //console.log("=========");

        // //console.log(response.data);
        
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })

    return {
      clientData,
    }
  },
  mounted(){
   var wizard = this.$refs.wizard
   wizard.activateAll()
  },
  methods: {
     tokenReceived(payload) {
          //console.log("token updated");
      //console.log(payload);
      this.demoToken=payload;

    },
    canSwitch(){
        return new Promise((resolve, reject) => {
       
 
          resolve(true);
        
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        //console.log("validationForm");
        //console.log(this.selectedCountries.length);

        if (this.selectedCountries.length > 0) {
          resolve(true);
        } else {
          reject();
        }
      });
    },

     loginConnected() {
      return new Promise((resolve, reject) => {
        //console.log("validationForm");
        

        if (this.demoToken!=null||this.demoToken.length>0) {
          resolve(true);
        } else {
          reject();
        }
      });
    },
    dataFetched(payload){ 
      //console.log("dataFetched");
      //console.log(payload);
    //  this.importedCountries = payload;
   
    },
    importCountries(payload){
      //console.log("importCountries");
      //console.log(payload);
  //    this.importedCountries = payload;
    
    },


    fecthCountries() {
      return new Promise((resolve, reject) => {
        var uri = this.customCountryEndpoint;

        var options = {
          method: "GET",

          headers: {},
        };

        const response = fetch(uri, options)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            //console.log(data);
            this.importedCountries = data;
            resolve(data);
          })
          .catch((error) => {
            //console.log(error);
            reject(error);
          });
      });
    },

    countrySelected(value) {
      this.selectedCountries = value;
    },

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>