<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Activity
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          :checked="localSettings.useCustomLogin"
          name="check-button"
          v-model="localSettings.useCustomLogin"
          switch
          inline
        >
          <span>Use Custom Authentication</span>  
        </b-form-checkbox>
        <b-badge variant="danger">
        Danger
      </b-badge>  
       
      </b-col>
       <b-col
        cols="12"
        class="mb-2"
      > 

      <b-list-group flush>
    <b-list-group-item class="d-flex b-none">
     <span class="mr-1">
          <b-badge
        variant="danger"
        
        class="badge-round"
      >
        !
      </b-badge>
      </span>
      <span><small class="text-danger "> This will disable users to register platform over signup.</small> </span>
    </b-list-group-item>

    <b-list-group-item class="d-flex">
      <span class="mr-1">
           <b-badge
        variant="danger"
        
        class="badge-round"
      >
        !
      </b-badge>
      </span>
      <span><small class="text-danger "> Once enabled API verification is required qith a demo account.</small> </span>
    </b-list-group-item>

    <b-list-group-item class="d-flex">
      <span class="mr-1">
         <b-badge
        variant="danger"
        
        class="badge-round"
      >
        !
      </b-badge>
      </span>
      <span><small class="text-danger "> Once enabled ; existing users (registered over signup) cant sign in their account.</small> </span>
    </b-list-group-item>
  </b-list-group>
    
         
       </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch2"
          :checked="localSettings.anotherSetting"
           v-model="localSettings.anotherSetting"
          name="check-button"
          switch
          inline
        >
          <span>Email me when someone answers on my form</span>
        </b-form-checkbox>
      </b-col>
     
      
      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
            @click.prevent="sendInformation"
        >
          Save changes
        </b-button>
      
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,BBadge, BListGroup, BListGroupItem 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
    BFormCheckbox, BListGroup, BListGroupItem 
  },
  directives: {
    Ripple,
  },
  props: {
    accountPreferences: {
      type: Object,
      default: () => {
       
      },
    },
  },
 
   created() {
     
     
    this.localSettings = this.accountPreferences.data==null||JSON.parse(JSON.stringify(this.accountPreferences.data)).accountsettings ==null?
    {  useCustomLogin: false }: JSON.parse(JSON.parse(JSON.stringify(this.accountPreferences.data)).accountsettings );//his.generalData;
    //console.log(this.localSettings);
    //console.log("account preferences");
  


  },
  data() {
   

    return {
      localSettings: {
           useCustomLogin: false ,
           anotherSetting: false,
    },
    }
  },
  methods: {
     sendInformation() {
      

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/f/settings`,
        data: {
          info: "accountpreferences",

          content: this.localSettings,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.accountPreferences))
    },
  },
}
</script>
