<template>
  <b-card>
    <b-form>
      <b-row>
        
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="MailIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Smtp Mail Sender Informations 
            </h4>
          </div>
        </b-col>
           <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
             
            <h6 class="mb-0 ">
             Type  : SMTP
            </h6>
          </div>
        </b-col>

          <b-col class="mt-1" md="6">
          <b-form-group
            label-for="smtp_host"
            label="Host"
          >
              <b-form-input
              id="smtp_host"
                class="form-control"
                
             
              v-model="localSettings.smtp_host"
              placeholder="Smtp Sender host "
            />
          </b-form-group>
        </b-col>
  <b-col class="mt-1" md="6">
          <b-form-group
            label-for="smtp_port"
            label="Port"
          >
            <b-form-input
              id="smtp_port"
              v-model="localSettings.smtp_port"
              placeholder="Smtp Sender Port"
            />
          </b-form-group>
        </b-col>
           <b-col md="6">
          <b-form-group
            label="Use Secure Connection"
            label-for="isSecured"
          >
           <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localSettings.isSecured"  name="isSecured" v-bind:value="false" >
              Unsecured
            </b-form-radio>
            <b-form-radio v-model="localSettings.isSecured"   name="isSecured" v-bind:value="true" >
              Secured
            </b-form-radio>
          </div>
          </b-form-group>
        </b-col>
         <b-col md="6">
          
        </b-col>
         
     
         <b-col md="6">
          <b-form-group
            label-for="smtp_username"
            label="SMTP Username "
          >
            <b-form-input
              id="account-google"
              v-model="localSettings.smtp_username"
              placeholder="Smtp Username"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="account-quora"
            label="SMTP Password"
          >
            <b-form-input
              id="smtp_password"
              v-model="localSettings.smtp_password"
              placeholder="SMTP Password"
            />
          </b-form-group>
        </b-col>

  <b-col md="6">
          <b-form-group
            label-for="account-google"
            label="From Mail"
          >
            <b-form-input
              id="account-google"
              v-model="localSettings.from_mail"
              placeholder="From Mail"
            />
          </b-form-group>
        </b-col>
         <b-col md="6">
          <b-form-group
            label-for="from_title"
            label="From Title"
          >
            <b-form-input
              id="from_title"
              v-model="localSettings.from_title"
              placeholder="From Title"
            />
          </b-form-group>
        </b-col>
        <!--/ quora -->

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <!-- Profile Connections -->
       
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
         
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,  BFormRadio,   BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "@axios";
import { ref } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
     BCardText,
    BFormRadio,
        BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    smtpsettings: {
      type: Object,
      default: () => {},
    },
  },
   created() {
     //console.log("email settings created");
    this.localSettings = this.smtpsettings.data==null||JSON.parse(JSON.stringify(this.smtpsettings.data)).emailsettings ==null?{}: JSON.parse(JSON.parse(JSON.stringify(this.smtpsettings.data)).emailsettings );//his.generalData;
    //console.log( this.optionsLocal);
    //console.log("email settings created");


  },
  data() {
    //console.log(this.smtpsettings);

    return {
      localSettings: {},
            isLoading: false,
             isUpdated: false,
             clevePort: {
         numeral: true
      
      },
    
    }
  },
  methods: {
     sendInformation() {
      //console.log(this.localSettings);
      //console.log("update course  is launching with id : " + this.courseId);

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/f/settings`,
        data: {
          info: "smtpsettings",
          file: null,
          content: this.localSettings,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.smtpsettings))
    },
  },
}
</script>
